.shop-cart thead tr {
    background: var(--primary-color);
    color: #000;
}
.shop-cart thead tr th {
    text-transform: uppercase;
    font-weight: 600;
    padding: 12px 10px;
}
.shop-cart thead tr th,
.shop-cart thead tr td {
    white-space: nowrap;
}
.cart-img {
    width: 80px;
}
.cart-img img {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #f4f4f4;
}
.shop-cart tr td {
    color: #000;
    vertical-align: middle;
    border-bottom: 1px solid #d1d1d1;
    border-top: none;
    position: relative;
    padding: 20px 10px;
    font-size: 16px;
}
.shop-cart tr td h5 {
    font-size: 18px;
}
.cart-qty {
    min-width: 150px;
}
.cart-qty button {
    color: var(--theme-color);
    border: 0;
    border-radius: 4px;
    padding: 4px 13px;
    background: #f4f4f4;
    margin: 0 5px;
}
.cart-qty button i {
    font-weight: 500;
}
.cart-qty input {
    width: 100px;
    padding: 4px 13px;
    border-radius: 4px;
    border: none;
    background: #f4f4f4;
    color: var(--theme-color);
    text-align: center;
}
.cart-remove:hover {
    color: var(--theme-color);
}
.cart-footer {
    margin-top: 40px;
}
.cart-coupon {
    margin-bottom: 25px;
}
.cart-coupon .form-group {
    position: relative;
}
.cart-coupon .form-control {
    box-shadow: none;
    padding: 15px 110px 15px 14px;
    border-radius: 10px;
}
.cart-coupon .form-control:focus {
    border-color: var(--theme-color);
}
.coupon-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 8px 14px;
    font-weight: 500;
    color: var(--color-white);
    border: none;
    background: var(--theme-color);
    border-radius: 10px;
    transition: var(--transition);
}
.coupon-btn:hover {
    background: var(--color-dark);
}
.cart-summary {
    float: right;
}
.cart-summary li {
    margin-bottom: 14px;
    width: 350px;
}
.cart-summary li span {
    float: right;
}
.cart-summary li strong {
    color: #000;
}
.cart-total {
    padding-top: 10px;
    border-top: 1px solid #d1d1d1;
}
.cart-total span {
    font-weight: 700;
    color: #000;
    background: #f6f6f6;
    padding: 5px 10px;
}
.cart-price span {
    font-size: 16px;
}
.cart-sub-total span {
    font-size: 16px;
}

.shop-cart {
    min-height: calc(100vh - 36vh);
}

a {
    &.cart-remove {
        padding: 7px 13px;
        background: #f00;
        border-radius: 5px;
        margin-left: 20px;
    }
}
