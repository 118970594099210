.mobile_chat_module {
    height: 100%;
    left: 0;
    right: 0;
    position: fixed;
    overflow-y: hidden;
    overscroll-behavior: none;

    .chat-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;

        .mobile-nav {
            position: static;
            top: 0;
            width: 100%;
            height: auto;
            z-index: 1001;
        }

        .top-head-box {
            background: #fff;
            padding: 20px 15px 19px;
            vertical-align: middle;
            align-items: center;
            display: flex;
            border-bottom: 1px solid var(--primary-color);
        }
    }

    .main_chatbox {
        padding-top: 0;
        position: relative;
        margin-bottom: 70px;
        padding-bottom: 70px;
        margin-top: 135px;
        padding-top: 0;

        .chat_inner_box {
            padding: 20px 0 20px;
            height: calc(100vh - 360px);
            overflow-y: auto;
            background-color: #fff;

            ul {

                &.users-list {
                    li {
                        margin-bottom: 20px;
                        padding-bottom: 20px;
                        border-bottom: 1px solid #f0f0f0;

                        &:last-child {
                            border: 0;
                        }
                    }
                }
            }

            .chat_view {
                .messages-list {
                    height: calc(100vh - 350px);
                    overflow-y: scroll;
                    padding-bottom: 0px;
                }

                p {
                    width: 100%;
                    height: auto;
                    word-wrap: break-word;
                    line-height: 24px !important;
                }

                .topbar_chatmain {
                    padding: 0 0 20px 0;
                    border-bottom: 1px solid #16ff96;
                }
            }
        }

        &.list_box {
            .chat_inner_box {
                height: calc(100vh - 210px);
                margin-bottom: 60px;
                padding-bottom: 60px;
            }
        }
    }

    .chat-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;

        .mobile-footer {
            position: static;
        }

        .send_msg_box {
            position: static;
            width: 100%;
            height: 70px;
            background: #fff;
            padding: 20px 10px;
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }
    .initials-circle{
        height: 80px;
        width: 80px;
        border-radius: 50%;
        font-size: 22px;
    }
}

.greenbtn {
    width: 75px;
    height: 58px;
    background: #16ff96;
    border-radius: 5px;
    min-width: 60px;
}

.green-border-bottom {
    border-bottom: 1px solid var(--primary-color);
}

.chat-img {
    margin: 10px 10px 0;
    background: rgba(240, 240, 240, 0.6);
    padding: 10px 15px;
    border-radius: 10px;
    display: inline-block;
}

.messages-list li h5 {
    padding-top: 10px;
}