.footer,
.mobile-footer {
    background: var(--secondary-color);
    padding-top: 80px;
    padding-bottom: 60px;

    a {
        svg {
            max-width: 129px;
            margin-bottom: 10px;
            width: 100px;
            height: 25px;
        }
    }

    p {

        font-style: normal;
        font-weight: var(--font-regular);
        font-size: var(--font16);
        line-height: 24px;
        color: var(--light-bg);
    }

    .social-links {
        display: flex;
        align-items: center;
        gap: 15px;
        padding-left: 0;

        li {
            list-style: none;
            a {
                svg {
                    margin-bottom: 10px;
                    width: 30px;
                    height: 25px;
                }
            }
        
        }
    }

    h5 {

        font-style: normal;
        font-weight: var(--font-medium);
        font-size: var(--font14);
        line-height: 22px;
        color: var(--gray-one);
        opacity: 0.5;

    }

    ul {
        padding-left: 0;

        li {
            list-style: none;

            a {

                font-style: normal;
                font-weight: var(--font-regular);
                font-size: var(--font16);
                line-height: 26px;
                color: var(--light-bg);
                text-decoration: none;

                &:hover {
                    color: var(--primary-color);
                }
            }
        }
    }

    &.home-footer {
        padding-top: 180px;
        padding-bottom: 60px;
    }
}

.mobile-footer {
    a {
        svg {
            max-width: 60px !important;
        }
    }
}