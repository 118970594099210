:root {
  --primary-color: #16ff96;
  --secondary-color: #2c2e3a;
  --theme-bg: #e8e8e8;
  --dark-bg: #1f1f1f;
  --light-bg: #ffffff;
  --red: #ff1616;
  --theme-h1: 48px;
  --theme-h2: 40px;
  --theme-h3: 36px;
  --theme-h4: 24px;
  --font16: 16px;
  --font18: 18px;
  --font14: 14px;
  --font-bold: 700;
  --font-black: 700;
  --font-medium: 500;
  --font-regular: 400;
  --font-normal: 300;
  --font12: 12px;
  --font-24: 24px;
  --border-color: #e8e8e8;
  --gray-one: #b1b1b1;
  --menu-a: #263238;
  --border-color1: #f0f0f0;
  --font32: 32px;
  --border-color2: #b3b3b3;
}

body {
  background: var(--light-bg);
  font-family: "Open Sans", sans-serif;
  color: --secondary-color;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

h4 {
  margin: 0;
}

.ptb {
  padding: 80px 0;
}

.darkbg {
  background: var(--secondary-color);
}

.main-slider {
  padding: 55px 0;

  .hero-banner {
    padding-bottom: 80px;
    height: auto;
    min-height: 410px;
    position: relative;

    .slide-content {
      width: 100%;

      h2 {
        font-size: var(--theme-h2);
        line-height: 54px;
        font-style: normal;
        font-weight: var(--font-regular);
        color: var(--secondary-color);
      }

      p {
        font-style: normal;
        font-weight: var(--font-regular);
        font-size: var(--font-regular);
        line-height: 22px;
        color: var(--dark-bg);
      }
    }

    img {
      float: right;
      height: 322px;
      width: 100%;
      object-fit: cover;
    }

    .swiper-button-prev {

      bottom: 0;
      z-index: 9999;

      &::after {
        background: url("../assets/images/slider-prev.svg") !important;
        width: 8px;
        height: 12px;
        cursor: pointer;
        position: relative;
        font-size: 0 !important;
      }
    }

    .swiper-pagination {


      .swiper-pagination-bullet-active {
        background: var(--dark-bg);
      }
    }

    .swiper-button-next {

      z-index: 9999;

      &::after {
        background: url("../assets/images/slider-next.svg") !important;
        width: 8px;
        height: 12px;
        cursor: pointer;
        position: relative;
        font-size: 0 !important;
      }
    }

    .bannr-navigators {
      display: inline-flex;
      align-items: center;
      column-gap: 10px;
      position: absolute;
      bottom: 120px;
      z-index: 1001;
      padding: 0 20px;

      .prev-btn {
        background: url("../assets/images/slider-prev.svg") !important;
        width: 10px;
        height: 22px;
        cursor: pointer;
        font-size: 0 !important;
        background-repeat: no-repeat;
        background-size: 100% 100% !important;
        margin-top: 5px;
      }

      .next-btn {
        background: url("../assets/images/slider-next.svg") !important;
        width: 10px;
        height: 22px;
        cursor: pointer;
        font-size: 0 !important;
        background-repeat: no-repeat;
        background-size: 100% 100% !important;
        margin-top: 5px;
      }

      .swiper-custom-pagination {
        width: auto !important;

        .swiper-pagination-bullet-active {
          background: var(--dark-bg);
        }
      }
    }
  }

  .category-boxes {
    margin-bottom: -150px;
    height: auto;

    .card {
      background: var(--light-bg);
      border: 1px solid var(--border-color);
      box-shadow: (0px 10px 14px rgba(0, 0, 0, 0.07));

      .card-title {
        font-style: normal;
        font-weight: var(--font-black);
        font-size: var(--font-24);
        line-height: 32px;
        color: var(--secondary-color);
      }

      .card-text {
        font-style: normal;
        font-weight: var(--font-regular);
        font-size: var(--font16);
        line-height: 22px;
        color: var(--dark-bg);
        min-height: 40px;
        height: auto;
      }
    }

  }
}

.how-it-work {
  background: var(--theme-bg);
  padding-top: 185px;
  padding-bottom: 0px;

  h4,
  .font24 {
    text-align: center;
  }

  .boxes {
    background: var(--light-bg);
    padding: 38px 32px;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
    text-align: center;
    margin: 100px 0 -80px;
    z-index: 9;
    position: relative;

    span {
      width: 56px;
      height: 56px;
      background: #2c2e3a;
      border-radius: 50%;
      margin: 0 auto 10px;
      display: block;
      padding: 11px 0;

      &.search {
        padding: 14px !important;
      }
    }

    .col-lg-4 {
      &:nth-child(2) {
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
      }
    }
  }

  &.lender-work {
    padding: 170px 0 80px;
    margin-bottom: 160px;

    .boxes {
      margin: 80px 0 -170px;
    }
  }
}

.itmes-list {
  background: url("../assets/images/make-extra-bg.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: auto;
  background-color: #00000070;
  background-blend-mode: multiply;
  padding-top: 188px;
  padding-bottom: 0;

  .list-boxes {
    margin: 100px 0 -90px;
    height: auto;

    .card {
      img {
        height: auto;
        min-height: auto;
      }
    }
  }

  &.renter-items {
    padding-top: 80px;

    .card {
      min-height: 270px;
    }
  }

  &.lender-items {
    padding: 80px 0 10px;

    .card {
      min-height: 290px;
    }

    .list-boxes {
      margin: 40px 0 23px;
      height: auto;
    }
  }
}

.addresmodal,
.border0 {
  &.modal {
    .modal-content {
      width: 538px !important;
      border-radius: 10px;
      margin: auto;
    }
  }
}

.darkbg {
  &.text-center {
    &.modal-body {
      padding: 0 !important;
    }
  }
}

.modal {
  .modal-content {
    width: 415px;
    max-width: 100%;
    margin: auto;
  }

  .modal-body {
    padding: 32px;
  }

  .header-area {
    padding: 32px 0 0;

    a {
      img {
        margin: 0 auto 15px;
        display: block;
      }
    }

    .nav {
      &.nav-pills {
        justify-content: space-between;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 5px;
        margin: 0 32px 25px;
        padding: 2px;

        a {
          background: var(--light-bg);
          padding: 8px 16px;
          width: 170px;
          font-style: normal;
          font-weight: var(--font-regular);
          font-size: var(--font16);
          line-height: 22px;
          color: var(--secondary-color);
          border: 0;

          &.active {
            background: var(--primary-color);
            font-weight: var(--font-regular);
            font-size: var(--font16);
            line-height: 22px;
            color: var(--secondary-color);
          }
        }
      }
    }

    .modal-title {
      font-style: normal;
      font-weight: var(--font-bold);
      font-size: 36px;
      line-height: 49px;
      text-align: center;
      color: var(--light-bg);
      margin-bottom: 15px;
    }
  }

  .middle-area {
    padding: 32px;
    background: var(--light-bg);

    a {
      font-style: normal;
      font-weight: var(--font-bold);
      font-size: var(--font12);
      line-height: 16px;
      text-align: right;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: var(--dark-bg);

      &.btn-social {
        text-align: center;
        text-decoration: none;
        text-transform: capitalize;
        color: var(--dark-bg);
        background: var(--light-bg);
        border: 1px solid var(--border-color);
        border-radius: 5px;
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 32px;
      }
    }

    .form-control {
      padding: 10px 50px;
      height: 38px;
      background: var(--light-bg);
      border: 1px solid var(--border-color);
      border-radius: 5px;
      position: relative;
    }

    .user-icon,
    .key-icon {
      position: absolute;
      top: 5px;
      left: 10px;
      z-index: 9;
      width: 22px;
      height: 22px;
    }

    .eye-icon {
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 9;
      width: 22px;
      height: 22px;
    }

    h5 {
      font-weight: var(--font-bold);
      font-size: var(--font12);
      line-height: 16px;
      text-align: center;
      color: var(--theme-bg);
      margin: 25px 0 25px;
      padding: 0;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        background: var(--border-color);
        top: 8px;
        left: 0;
        width: 45%;
        height: 1px;
      }

      &::after {
        content: "";
        position: absolute;
        background: var(--border-color);
        top: 8px;
        right: 0;
        width: 45%;
        height: 1px;
      }
    }

    &.new-address {
      .form-control {
        padding: 10px 10px;
      }
    }
  }
}

.picture-modal,
.delete-items {
  .modal-content {
    width: 100%;

    .product-img {
      img {
        width: 100%;
        max-width: 100%;
        height: 200px;
        object-fit: cover;
      }

      .product-detail {
        padding: 13px 18px;
        border: 1px solid var(--border-color1);
      }
    }
  }
}

.top-breadcrumb {
  padding-top: 24px;
  padding-bottom: 24px;

  .form-select {
    width: auto;
    border: 0;
    box-shadow: none;
    text-align: left;
  }

  &.top-area {
    ._search {
      .btn-theme {
        height: 40px;
        margin-top: 30px !important;
      }
    }
  }
}

.filterbar {
  padding-top: 24px;
  padding-bottom: 24px;
  background: #f3f3f3;

  ::placeholder {
    color: var(--secondary-color);
  }
}

.flex-end {
  justify-content: end;
}

input[type="date"] {
  text-transform: uppercase;
}

.nav-pills {
  #left-tabs-example-tab-first {
    border-radius: 5px 0px 0px 5px;
  }

  #left-tabs-example-tab-second {
    border-radius: 0px 5px 5px 0px !important;
  }

  .nav-link {
    height: 38px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #2c2e3a;

    &.active {
      height: 38px;
      background: #16ff96;
      border: 1px solid #e8e8e8;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #2c2e3a;
    }

    .show>.nav-link {
      height: 38px;
      background: #16ff96;
      border: 1px solid #e8e8e8;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #2c2e3a;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .show>.nav-link {
      color: rgb(22, 255, 150);
      border-bottom: 1px solid #16ff96;
    }
  }
}

.new-listing {
  .nav-link {
    background: transparent;
    border: 0;

    &.active {
      background: transparent;
      border: 0;
    }
  }
}

.form-control {
  height: 38px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  position: relative;

  &:focus {
    box-shadow: none !important;
    border-color: var(--border-color);
  }

  &::placeholder {
    text-transform: capitalize;
  }
}

textarea {
  &.form-control {
    height: auto;
  }
}

.eye-icon {
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 9;
  width: 22px;
  height: 22px;
}

.datepicker {
  span {
    right: 15px;
    top: 5px;
    background: #fff;
    pointer-events: none;
    cursor: pointer;

    &.custom-picker {
      background: transparent;
      pointer-events: all;
    }
  }


}

.category-boxes {
  height: calc(100vh - 30vh);

  &.heightauto {
    height: auto;
  }

  .card {
    background: #ffffff;
    border: 0px solid var(--border-color);
    border-radius: 0;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
    min-height: 350px;
    border: 0 !important;

    &.active {
      border: 1px solid #16ff96 !important;
    }

    .btn {
      margin-bottom: 0;
    }

    p {
      span {
        svg {
          margin-left: 5px;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.card-img-top {
        min-height: 150px;
        height: 250px;
        overflow: hidden;
        border-radius: 0;
        object-fit: cover;
      }
    }

    .img-box {
      img {
        width: 40px;
        height: 40px;
      }
    }

  }

  .card-img-top {
    height: 200px;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .initials-circle{
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.sidevarscroller {
  overflow: auto;
  height: calc(100vh - 30vh);

  .card {
    height: auto;
    border: 1px solid var(--border-color) !important;

    img {
      height: auto;
    }

    &.active-lft-card {
      border: 1px solid #16ff96 !important;
    }
  }
}

.mappingimg {
  height: calc(100vh - 30vh);
  width: 100%;
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .map-loc-card {
    position: fixed;
    bottom: 150px;
    left: 10px;
    right: 10px;
    z-index: 9;
    background-color: #fff;
    border-radius: 15px;

    .map-img-card {
      width: 120px;
      height: 100px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 12px;

      .cross-grn {
        position: absolute;
        left: 5px;
        background: #455A64;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 18px;
        top: 5px;
        display: inline-block;
        border-radius: 50%;
        opacity: 0.6;

        svg {
          fill: #16ff96;
          stroke: #16ff96 !important;
        }
      }
    }

    .mappingimg .map-loc-card .map-img-card {
      width: 120px;
      height: 100px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 12px;
      position: relative;
    }

    .stars-card {
      span {
        font-size: 16px !important;
      }
    }
  }
}

.absoluteboxmain {
  position: absolute;
  width: 90%;
  left: 0;
  top: 0;
}

@media (max-width: 375px) {
  .gap-3.otp-input input{
    width: 2.5rem !important;
  }
}
@media (max-width: 991px) {
  .absoluteboxmain {
    position: relative;
  }
}

.top-head {
  background: #fff;
  padding: 60px 0;
  vertical-align: middle;
  align-items: center;
  display: flex;
  border-bottom: 1px solid var(--primary-color);

  &.bottom-border {
    border-bottom: 1px solid #16ff96;
  }

  h4 {
    text-align: left;
  }
}

.top-area {
  background: var(--theme-bg);
  padding: 24px 0 40px;

  .search-form {
    position: relative;

    span {
      &.searc-icon {
        position: absolute;
        top: 35px;
        right: 15px;
      }

      &.cross {
        position: absolute;
        top: 29px;
        right: 35px;
      }
    }

    input[type="text"] {
      padding-right: 60px;
    }
  }

  .form-select {
    width: 100%;
    border: 0;
    box-shadow: none;
    text-align: left;
  }

  .btn-theme {
    height: 36px;
    margin-top: 30px;
  }

  &.newsletter {
    .form-control {
      border: 0;
      border-radius: 0;
      line-height: 22px;
      background: transparent;
      border-bottom: 1px solid var(--primary-color);
    }
  }
}

.result-area {
  table {
    tr {
      align-items: center;
      display: flex;
      justify-content: space-between;

      td {
        border: 0;
      }
    }
  }
}

.starlist {
  svg {
    margin-right: 7px;
  }
}

.greytable {
  background: rgba(232, 232, 232, 0.5);
  border-radius: 5px;

  td {
    padding: 8px 15px;
    border: 0;
  }
}

.noborder {
  &.greytable {
    td {
      padding: 8px 5px;
      border: 0;
    }
  }
}

.usrimg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image-gallery-bullets {
  .image-gallery-bullet {
    padding: 0 !important;
    background: rgba(44, 46, 58, 0.3) !important;
    border: 0 !important;
    width: 8px !important;
    height: 8px !important;

    &.active {
      transform: 0 !important;
      border: 0 !important;
      background: #1f1f1f !important;
    }
  }
}

.image-gallery-slide-wrapper {
  height: auto;
  overflow: hidden;
  min-height: calc(400px - 110px);

  .image-gallery-swipe {
    height: 100%;

    .image-gallery-slides {
      height: 100%;
    }

    .image-gallery-image {
      height: 400px;
      object-fit: cover;
      width: 100%;
    }
  }
}

.image-gallery-thumbnail {
  & .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    height: 70px;
    line-height: 0;
    min-height: 70px;
    object-fit: cover;
  }
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 0 10px !important;
  top: 94% !important;

  transform: 0 !important;

  .image-gallery-svg,
  .image-gallery-svg {
    height: auto !important;
    width: 13px !important;
  }
}

.image-gallery-right-nav {
  left: 57% !important;
}

.image-gallery-left-nav {
  transform: 0 !important;
  left: 36% !important;
}

.image-gallery-thumbnail {
  width: 25% !important;

  &.active,
  &:focus {
    border: 4px solid var(--primary-color) !important;
  }
}

.rate-area {
  background: rgb(22, 255, 150, 0.5);
  padding: 66px 0 50px;

  .boxes {
    text-align: center;

    .col-lg-4 {
      &:nth-child(2) {
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
      }
    }
  }
}

.rate-list {
  ul {
    padding-left: 0;
    padding-top: 55px;

    li {
      font-size: var(--font-24);
      font-weight: var(--font-bold);
      list-style: disc;
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 10px;

  li {

    &.active,
    &:hover {
      a {
        padding: 6px 10px !important;
        width: 28px !important;
        height: 28px !important;
        background: rgba(22, 255, 150, 0.05);
        border: 1px solid rgba(22, 255, 150, 0.15) !important;
        color: var(--dark-bg);
        font-size: var(--font12);
        line-height: 15px;
        border-radius: 5px;
        text-align: center;
        box-shadow: none;
      }
    }

    a {
      padding: 6px 10px;
      width: 28px;
      height: 28px;
      border: 1px solid var(--border-color);
      border-radius: 5px;
      color: var(--dark-bg);
      font-size: var(--font12);
      line-height: 15px;
      text-align: center;
      display: inline-flex;
    }
  }
}

.subtop-menu {
  li {
    margin-right: 48px;
    font-weight: 700;
    font-size: var(--font16);
    line-height: 22px;
    color: #2c2e3a;
    position: relative;

    &.active {
      font-size: 24px;
      line-height: 32px;

      &::after {
        content: "";
        width: 100%;
        position: absolute;
        height: 1px;
        background: #2c2e3a;
        bottom: -20px;
        left: 0;
      }
    }

    a {
      color: #2c2e3a;
    }
  }
}

.bggrey {
  background-color: #f0f0f0;
}

.sidebarnav,
.accordion {
  &.nav {
    &.nav-pills {
      a {
        height: auto;
        padding: 5px 0;
        background: transparent;
        border: 0;
        font-size: 16px;
        line-height: 22px;
        color: var(--secondary-color);

        &.show {
          font-weight: var(--font-bold);
          color: var(--secondary-color);
        }

        &:focus-visible {
          outline: 0 !important;
        }
      }

      .nav-item {
        &.show {
          &.dropdown {
            min-height: 130px;
            height: auto;
          }
        }
      }
    }
  }

  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 10px;
      vertical-align: 3px;
      content: "";
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      background: url("../assets/images/dropdark.svg");
      width: 14px;
      height: 8px;
      top: 2px;
      position: relative;
    }

    &.show {
      &::after {
        display: inline-block;
        margin-left: 10px;
        vertical-align: 3px;
        content: "";
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
        border-left: 0;
        background: url("../assets/images/updark.svg");
        width: 14px;
        height: 8px;
        top: 2px;
        position: relative;
      }
    }
  }

  .dropdown-menu {
    &.show {
      background: transparent;
      border: 0;
      padding: 0;
      transform: translate(0px, 30px) !important;
    }
  }
}

.accordion {
  background: transparent;

  .accordion-item {
    background: transparent;
    border: 0;

    .accordion-button {
      background: transparent;
      padding: 0;
      border: 0;
      color: var(--secondary-color);
      font-weight: var(--font-bold);

      &:focus {
        box-shadow: none;
      }

      &:not(.collapsed) {
        box-shadow: none;

        &::after {
          background-image: var(--bs-accordion-btn-icon) !important;
        }
      }
    }

    .accordion-body {
      padding-top: 0;
      padding-bottom: 0;
      border-left: 2px solid #000;
      position: relative;

      a {
        height: auto;
        padding: 5px 0;
        background: transparent !important;
        border: 0 !important;
        font-size: 16px;
        line-height: 22px;
        color: var(--secondary-color);

        &.show {
          font-weight: var(--font-bold);
          color: var(--secondary-color);
        }

        &:focus-visible {
          outline: 0 !important;
        }

        &.active {
          &::before {
            content: "";
            position: absolute;
            border-left: 2px solid #16ff96 !important;
            z-index: 9;
            width: 1px;
            height: 35px;
            left: -2px;
            margin: -5px 0;
          }
        }
      }

      .nav-item {
        display: block;
        width: 100%;
      }
    }
  }
}

.custom-checkbox {
  input {
    border-radius: 50%;
    margin: 0;
    border-color: #2c2e3a;

    &:checked {
      background-color: #2c2e3a;
      border-color: #2c2e3a;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.checkout {
  .card {
    border-color: var(--border-color1);
    border-radius: 0;
    position: relative;
  }

  a {
    .card {
      border-radius: 5px;

      .down-icon {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }

  .card {
    border-radius: 5px;
    cursor: pointer;

    .down-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .dropdown {
    width: 20rem;
    border-radius: 5px;
    border: 1px solid var(--border-color1);

    .dropdown-toggle {
      width: 20rem;
      border-radius: 5px;
      border: 1px solid var(--border-color1);
      text-align: left;
    }
  }
}

.summary-box {
  background: var(--border-color1);
  border-radius: 5px;
  padding: 16px;
}

.addresscard {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 10px 16px;
  min-height: 250px;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;

  &.addadress {
    border: 1px dashed #2c2e3a !important;
    text-decoration: none;
  }

  &.payment-gredientcard {
    &.card-group {
      background: linear-gradient(108.62deg, #16ff96 -65.59%, #263238 98.35%);
      border-radius: 5px;
      border: 0;
      height: 100%;
    }
  }
}

.addresscard {
  &.shadow-none {
    filter: none;
  }
}

.accountmain {
  min-height: calc(100vh - 70vh);

  .cancel-icon {
    position: absolute;
    right: 10px;
  }

  &.new-listing {
    .card {
      border-radius: 0;
      background: var(--light-bg);
      border: 0;
      box-shadow: (0px 10px 14px rgba(0, 0, 0, 0.07));
      margin-bottom: 20px;
      position: relative;

      .card-text {
        font-size: var(--font16);
        line-height: 22px;
        font-weight: var(--font-bold);
        color: var(--secondary-color);
      }

      .card-img-top {
        border-radius: 0;
      }

      .butns {
        position: absolute;
        right: 10px;
        top: 10px;
        gap: 10px;
      }
    }
  }

  .room {
    height: auto;
    min-height: 224px !important;
    width: 100%;
    border-bottom: 1px solid rgb(44 46 58 / 10%);
    padding: 30px;

    img {
      width: 145px;
      height: 73px;
      max-width: 100%;
      margin: 10px auto 10px;
      display: block;
      border-radius: 5px;
      cursor: pointer;
      object-fit: cover;

      &.active {
        border: 2px solid var(--primary-color);
      }
    }
  }

  .green-border {
    border-right: 1px solid var(--primary-color);
  }

  .users-list {
    padding: 50px 30px 30px;

    li {
      border-bottom: 1px solid var(--border-color);
      padding: 20px 10px;
      margin-bottom: 20px;

      &:last-child {
        border: 0;
      }

      &.active {
        background-color: #fff;
      }

      ul {
        li {
          padding: 5px;
          border: 0;
          margin-bottom: 5px;
        }
      }
    }
  }

  .chat-view {
    min-height: 70vh;
    padding-bottom: 20px;

    ul {
      padding: 40px 0 20px;
    }

    .send-msg {
      border-radius: 30px;
      background: #eee;
      min-height: 61px;
      padding: 15px;
    }
  }

  .no-data-box {
    min-height: calc(100vh - 45vh);
    overflow: auto;
  }
}

.form-select {
  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.payment-gredientcard.card-group {
    background: linear-gradient(108.62deg, #16ff96 -65.59%, #263238 98.35%);
    border-radius: 5px;
    border: 0;
  }

  &.add-payment {
    height: 100%;
    background: #fff;
    border: 1px dashed #2c2e3a;
    border-radius: 5px;
  }
}

span {
  &.required {
    color: #f00;
    font-size: var(--font14);
    line-height: 24px;
    background: transparent;
  }
}

label {
  &.form-check-label {
    font-weight: var(--font-regular);
    font-size: var(--font16);
    line-height: 22px;
    color: var(--secondary-color);
  }
}

input {
  &.form-check-input {
    border-color: var(--secondary-color);
    border-radius: 0 !important;
    border-width: 2px;
  }
}

.form-check-input {
  &[type="radio"] {
    border-radius: 50% !important;
    margin-top: 0;
    width: 22px;
    height: 22px;
    position: relative;
    background: #fff !important;
    cursor: pointer;
  }

  &:checked[type="radio"] {
    &::before {
      position: absolute;
      content: "";
      border-color: var(--secondary-color);
      border-width: 2px;
      background: #16ff96;
      width: 12px;
      height: 12px;
      border-radius: 50% !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.form-check-input {
  &:checked {
    background-image: url("../assets/images/checkIcon.svg") !important;
    background-color: transparent;
    border-color: var(--secondary-color) !important;
  }

  &:focus {
    border-color: var(--secondary-color) !important;
    box-shadow: none;
  }

  &[type="radio"] {
    background-image: none !important;
  }
}

.heightauto {
  height: auto;
}

.custom-upload-file,
.upload-photo {
  width: 100%;
  height: auto;
  background: var(--light-bg);
  border: 1px dashed var(--secondary-color);
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  input[type="file"] {
    min-height: 300px;
    width: 100%;
    display: none;
  }

  label {
    font-size: var(--font12);
    line-height: 16px;
    font-weight: var(--font-bold);
    color: var(--secondary-color);
  }
}

.upload-file {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }

  label {
    font-size: var(--font12);
    line-height: 16px;
    font-weight: var(--font-bold);
    color: var(--secondary-color);
    cursor: pointer;
  }
}

.calender {
  .fc-col-header-cell {
    a {
      font-size: var(--font12);
      font-weight: var(--font-medium);
    }
  }

  a {
    color: var(--secondary-color);
    font-size: var(--font16);
    font-weight: var(--font-bold);
  }

  .fc {
    .fc-daygrid-day {
      &.fc-day-today {
        background-color: transparent;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100px;
          height: 1px;
          background: var(--secondary-color);
          top: 40px;
          left: -30px;
          transform: rotate(-45deg);
          display: none;
        }
      }
    }

    .fc-toolbar {
      align-items: start;
      justify-content: start;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .fc-h-event {
    border: 0;
    background: rgba(22, 255, 150, 0.4) !important;

    .fc-event-main {
      color: var(--secondary-color);
    }
  }

  .fc-direction-ltr {
    .fc-daygrid-event {
      &.fc-event-start {
        min-height: 50px;
        border-radius: 100px 0 0 100px;
      }

      &.fc-event-end {
        min-height: 50px;
        border-radius: 0 100px 100px 0;

        .fc-event-time {
          opacity: 0;
        }
      }
    }
  }

  .fc-event {
    &.fc-event-start {
      &.fc-event-end {
        &.multiple_day {
          &.fc-daygrid-event {
            &.fc-daygrid-block-event {
              &.fc-h-event {
                min-height: 50px;
                border-radius: 100px;
              }
            }
          }
        }
      }
    }
  }

  .fc-theme-standard td {
    border: 1px solid var(--border-color1);
  }

  .fc-theme-standard th {
    border: 0;
    text-align: left;
  }

  .fc-theme-standard {
    .fc-scrollgrid {
      border: 0;
    }
  }

  a {
    &.fc-event {
      overflow: hidden;

      &.fc-event-future {
        &.multiple_day {
          &.fc-daygrid-event {
            &.fc-daygrid-block-event {
              &.fc-h-event {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }

  .fc-event-start p {
    display: none;
  }

  .fc-event-start.fc-event-end p {
    display: block;
  }

  .fc-event-main p {
    display: flex !important;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 5px;
    }

  }

  a {
    &.fc-event {
      &.fc-event-start {
        &.fc-event-future {
          .fc-event-main {
            display: flex !important;

            img {
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .fc {
    .fc-daygrid-day-top {
      justify-content: start;
      z-index: 999999;
      position: relative;

    }

    .fc-bg-event {
      opacity: 1;
    }

    .fc-daygrid-bg-harness {
      overflow: hidden;
    }

    .single_day {
      &.fc-bg-event {
        background-color: transparent !important;

        .booking {
          display: none;
        }

        .price {
          display: block;
          margin: 0 15px 0;
          position: absolute;
          top: 10px;
          right: 0;


          p {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
          }
        }
      }
    }

    .blocked_class {
      &.fc-bg-event {
        position: relative;
        background-color: transparent !important;
        opacity: 1;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        left: 2px;
        top: 4px;

        p {
          margin: 0;
          opacity: 0;
        }

        .booking {
          display: none;
        }

        .price {
          display: none;
        }
      }
    }

    .blocked_class {
      position: relative;

      &::after {
        position: absolute;
        background: url(../assets/images/lines-pattren.png);
        width: 200px;
        height: 200px;
        left: -6px;
        right: 0px;
        bottom: 0;
        top: -6px;
        content: "";
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .buffer_day_before {
      &.rounded-pill-before {
        position: relative;
        background-color: transparent !important;
        opacity: 1;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        left: 2px;
        top: 4px;

        p {
          margin: 0;
          opacity: 0;
        }

        .booking {
          display: none;
        }

        .price {
          display: none;
        }
      }
    }

    .buffer_day_before {
      position: relative;

      &::after {
        position: absolute;
        background: url(../assets/images/shipping.png);
        width: 145px;
        height: 145px;
        left: 26px;
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .fc-daygrid-day-number {
    padding: 10px;
    position: relative;
    z-index: 4;
    text-align: center;
  }
}

span {
  &.user-img {
    min-width: 40px;
    width: 40px;
    height: 40px;
    top: 2px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;

    img {
      object-fit: cover;
    }
  }
}


.product_img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.category-select {
  option {
    font-size: 16px;
    line-height: 32px;
    font-weight: var(--font-bold);

    &:hover,
    &:checked,
    &:focus {
      background: rgb(22 255 150 / 10%) !important;
    }
  }
}

.bookmark-icon {
  position: absolute;
  right: 15px;
  top: 5px;
  background: rgba(30, 30, 30, 0.56);
  z-index: 9;
  border-radius: 100px;
  padding: 7px;
}

.card-img-top {
  &.product-img {
    width: 100%;
    height: 250px;
    min-height: 250px;
    object-fit: cover;
  }
}

.eventimage {
  width: 100px;
}

.multiple_day {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;

  p {
    margin-bottom: 0;
  }

  .eventimage {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

}

.error {
  color: red;
}

sup {
  &.item-count {
    background: #16ff96;
    color: #000;
    padding: 3px 6px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 20px;
    font-weight: var(--font-bold);
    left: -5px;
    top: -8px;
    border: 2px solid #000;
  }
}

.modal {
  .modal-content {
    border-radius: 10px;
    overflow: visible;
    margin: auto;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

.bottom20 {
  padding-bottom: 20px !important;
}

.top46 {
  padding-top: 46px !important;
}

.category-detailview {
  .btn {
    opacity: 1;
  }
}

.cursor {
  cursor: pointer;
}

.react-dropdown {
  position: relative;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' strokeWidth='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus-visible {
    outline: none;
  }

  .optList {
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    /* !important will affect the rest of your CSS despite being in a layer */
    margin: 1px 0 !important;
    padding: 10px 0 !important;
    box-sizing: border-box;
    min-width: 100%;
    max-height: 20em;
    overflow-y: auto;
    overflow-x: hidden;
    border-top-width: 0.1em;
    border-radius: 0 0 0.4em 0.4em;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
    background: #fff;
    transform: translateY(1px);
    z-index: 5;

    &.hidden {
      max-height: 0;
      visibility: hidden;
    }
  }

  .value {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .option {
    padding: 7px 15px;
    opacity: 0;
    animation-delay: calc(40ms * var(--index)) !important;
  }

  &.active {
    &::after {
      clip-path: polygon(50% 0, 50% 0, 100% 100%, 0 100%);
    }

    .option {
      animation: drop 220ms ease forwards;
    }
  }

  .highlight {
    background-color: rgba(22, 255, 150, 0.1);
    color: var(--dark-bg);
    font-weight: var(--font-regular);
  }

  select {
    visibility: hidden;
    max-height: 0;
    position: absolute;
    padding: 0;
    margin: 0;
  }
}

@keyframes drop {
  from {
    transform: translateY(-5px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.mobile-footer {
  a {
    &.active {
      p {
        color: #16ff96 !important;
        font-weight: 700;
      }

      svg {
        path {
          fill: #16ff96;
        }
      }

      .bagwhite-icon {
        svg {
          path {
            fill: none;
            stroke: #16ff96;
          }
        }
      }
    }
  }
}

.nodata {
  padding-top: 40px;
  padding-bottom: 20px;
}

.modal-body {
  .text-start {
    margin-bottom: 0;
  }
}

.accountmain {
  .col-lg-8 {
    .tab-content {
      min-height: calc(100vh - 20vh);
    }
  }
}

.chatboxmain {
  min-height: 39vh;
  overflow: auto;
  max-height: 44vh;
}

.messsagemenu {
  li.active::after {
    bottom: -17px;

    a {
      font-size: 40px;
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.height42 {
  height: 42px !important;
  line-height: 28px !important;
}

.ratingcards {
  svg {
    min-width: 40px;
    margin-top: 10px;
  }
}

.smallmodal {
  .middle-area {
    padding-bottom: 10px;
  }
}

.load_previous {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font14);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.chat-img {

  margin: 10px;
  background: rgb(240 240 240 / 60%);
  padding: 10px 15px;
  border-radius: 10px;
  display: inline-block;

  img {
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }


}

.rental-chat-img {
  width: 100%;
  height: 250px;
  margin-bottom: 40px;
  padding-right: 15px;

  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.topbar-chatmain {
  visibility: hidden;
}

.toggledivdetail {
  display: none;
}

.accountmain {
  .green-border {
    &.rightsidebar {
      padding: 0 15px !important;
    }
  }

  .users-list {
    li {
      img {
        border-radius: 50%;
        object-fit: cover;

        &.chat-product-img {
          border-radius: 0;
          width: 100%;
          height: 180px;
          object-fit: cover;
          margin-bottom: 20px;
        }
      }

      .last-msg-img {
        object-fit: cover;
        width: 30px;
        height: 30px;
        border-radius: 0% !important;
      }
    }
  }

  .chat-view {
    ul {
      li {
        img {
          &.user-small {
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.image-gallery-left-nav {
  left: 35% !important;
  transform: translate(-35%, -50%) !important;
  bottom: 0px;
}

.image-gallery-right-nav {
  left: 65% !important;
  right: auto !important;
  transform: translate(-65%, -50%) !important;
  bottom: 0px;
}

.mainbox_outer {
  min-height: calc(100vh - 375px);
}

.sidevarscroller .card-body span {
  font-size: 19px !important;
}

#headerlogin {
  margin-right: 0;
}

.search-form {
  margin-right: 20px;
}

.sidevarscroller .card-body span {
  font-size: 19px !important;
}

.min-height140 {
  min-height: 140px !important;
}

#headerlogin {
  margin-right: 0;
}

.search-form {
  margin-right: 20px;
}

.addresdropdown-main {
  input {
    width: 100%;
    border: 0;
    box-shadow: none;
    outline: none;
  }
}

.profilelargeimg {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin: auto;
  display: block;
  border-radius: 50%;
  background: #f1f1f1;
  margin-bottom: 30px;
}

.addresdropdown-main {
  position: relative;

  .addressdetailbox {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 10px;
    border-radius: 5px;
    z-index: 9;
    width: 100%;
    background-color: #fff;
    border: 1px solid #f0f0f0 !important;
    overflow-y: auto;
    height: auto;
    overflow-x: hidden;

    .addressdropdown {
      border: 1px solid #f0f0f0;
      border-radius: 10px !important;
      min-height: 140px !important;
    }
  }
}

.starslist {
  svg {
    margin-right: 3px;
  }

  &.smallsize {
    svg {
      width: 30px;
      margin-right: 5px;
    }
  }
}

.form-check-input[type="radio"] {
  border: 2px solid var(--secondary-color) !important;
}

.height42 {
  height: 42px;
}

.width200 {
  width: 200px !important;
  min-width: 200px !important;
}

.thumbnailimg {
  border: 1px solid #f0f0f0;
  height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
    object-position: center;
    height: 90%;
    width: 90%;
  }
}

.mobilesidebarlist {
  .accordion-header {
    border-top: 1px solid rgba(22, 255, 150, 0.3);
    line-height: 40px;
  }

  .accordion {
    margin-bottom: 20px;
  }
}

/* Review page scss */
.review {
  .card {
    background: #ffffff;
    border: 1px solid var(--border-color1);
    border-radius: 15px;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
    min-height: 265px;
    height: auto;

    .user-info {
      img {
        width: 111px;
        height: 111px;
        margin-bottom: 20px;
      }
    }

    .rating_review {
      p {
        &.second_child {
          padding: 20px 0;
          border-top: 1px solid var(--primary-color);
          border-bottom: 1px solid var(--primary-color);
        }
      }
    }
  }

  .reviewer_info {
    .box {
      border-bottom: 2px solid var(--primary-color);
    }

    .reviewSwiper {
      margin-bottom: 70px;

      .swiper-slide {
        .card {
          border-radius: 5px;
          border: 1px solid var(--border-color2);
          padding: 30px;
          padding-bottom: 0;

          p {
            &.first_child {
              min-height: 100px;
            }
          }

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .reviewListingSwiper {
      .swiper-slide {
        .card {
          img {
            height: 200px;
            width: 100%;
            object-fit: cover !important;
          }
        }
      }
    }

    .swiper-horizontal {
      padding-top: 70px;
    }

    .swiper-button-next {
      right: 0px !important;
      left: inherit;
      top: 30px;
      bottom: inherit;
      z-index: 9999;

      &::after {
        font-family: none;
        background: url("../assets/images/slidericon-right.svg") !important;
        width: 22px;
        height: 22px;
        cursor: pointer;
        position: relative;
        font-size: 0 !important;
      }
    }

    .swiper-button-prev {
      left: inherit !important;
      right: 30px;
      top: 30px;
      bottom: inherit;
      z-index: 9999;

      &::after {
        font-family: none;
        background: url("../assets/images/slidericon-left.svg") !important;
        width: 22px;
        height: 22px;
        cursor: pointer;
        position: relative;
        font-size: 0 !important;
      }
    }
  }
}

div.pac-container {
  z-index: 99999999999 !important;
}

.produt-list {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - -23vh);
}

.main-box {
  max-height: calc(100vh - 190px);
  overflow: auto;
}

.marker_label_job_map {
  color: #fff;
  background: transparent;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 30px;
  margin-top: -36px !important;
  margin-left: -15px !important;

  label {
    cursor: pointer;
  }

  /* Map Marker's Label CSS */
  &.lt10 {
    margin-top: -54px !important;
    margin-left: -22px !important;
  }

  &.lt100 {
    margin-top: -57px !important;
    margin-left: -22px !important;
  }

  &.lt500 {
    margin-top: -62px !important;
    margin-left: -22px !important;
  }

  &.lt1000 {
    margin-top: -62px !important;
    margin-left: -22px !important;
  }

  &.lt5000 {
    margin-top: -68px !important;
    margin-left: -23px !important;
  }

  &.lt10000 {
    margin-top: -73px !important;
    margin-left: -32px !important;

  }

  &.gt10000 {
    margin-top: -90px !important;
    margin-left: -22px !important;
  }
}

.stars-card span {
  padding-right: 2px;
  line-height: 26px;
}

.fc {
  .fc-highlight {
    background-color: transparent;
  }
}

.selectedDate {
  background-color: #16ff962a !important;

  .booking {
    img {
      display: none !important;
    }

    .high-dis {
      display: none !important;
    }
  }

  .price {
    p {
      display: none !important;
    }
  }
}

.placeholder-img {
  height: 144px;
  width: 205px;
  margin-bottom: 5px;
  object-fit: cover;
}

.modal .modal-content {
  border-radius: 10px;
  overflow: hidden;
}

.addresmodal .modal-content {
  overflow: visible;
}

.grid-list-btn {
  justify-content: flex-end;

  .nav.nav-pills {
    background: #fff;
    padding: 1px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;

    #left-tabs-example-tab-second {
      border-radius: 5px 5px 5px 5px !important;
    }

    #left-tabs-example-tab-first {
      border-radius: 5px 5px 5px 5px;
    }
  }

  .nav-pills .nav-link {
    border: none;
  }
}

.accountmain {
  .btn-theme-outline {
    padding: 11px 24px !important;
    height: auto !important;
    font-size: var(--font14);
  }

  .btn-danger {
    padding: 12px 24px !important;
    height: auto !important;
    font-size: var(--font14);
  }
}

.image-gallery-icon {
  z-index: 99 !important;
}

.addres-checkout {
  .addressdetailbox {
    max-height: 350px;
  }

  .addresscard {
    min-height: 160px;
  }
}

.blocked_class {
  position: relative;
}

.multiple_day p {
  display: flex;
}

.high-dis {
  b {
    display: block;
  }

  i {
    font-size: 11px;
  }
}

.payement-view-card {
  height: 200px !important;
  min-height: 200px !important;
}

.location_icon {
  position: absolute;
  bottom: 115px;
  right: 10px;
  background: white;
  margin: 1px;
  cursor: pointer;
  padding: 5px;
}

.card-search {
  .searc-icon {
    position: absolute;
    top: 29px !important;
    right: 15px;
  }
}


a {
  &.btn {
    &.msg-btn {
      padding: 12px 20px;
      height: 40px;

    }
  }
}

.multiple_day {
  .fc-event-main {
    .booking {
      display: flex;
    }

    .price {
      display: none;

    }
  }
}

.rdp {
  --rdp-cell-size: 50px !important;
  --rdp-accent-color: var(--primary-color) !important;
  --rdp-background-color: #16ff961a !important;
  --rdp-selected-color: #000 !important;
  margin: 0 !important
}


.chatboxmain {
  &.empty-box {
    min-height: calc(100vh - 43vh);
    max-height: calc(100vh - 43vh);
    overflow: hidden;
  }

  &.chat-active {
    height: calc(100vh - 48vh);
    overflow-y: auto;
    max-height: calc(100vh - 51vh);
  }
}

.leftslidetoggle {

  height: calc(100vh - 30vh);

  .initials-circle{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    font-size: 22px;
  }
}

.snd-btn {
  position: absolute;
  right: 5px;
  background-color: #16ff96;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}


.main_box {
  max-height: calc(100dvh - 220px);
  padding: 20px 0 20px;

  &.map-view {
    max-height: calc(100dvh - 340px);

    .tab-height {
      margin-bottom: 0;
    }
  }
}

.mobile-footer-fixed {
  position: fixed;
  z-index: 1;
}

.revew-prf-img {
  width: 30px;
  height: 30px;
  overflow: hidden;
}

.review-list {
  max-height: 550px;
  overflow-y: scroll;
  padding-right: 10px;

  .box {
    border-bottom: 2px solid var(--primary-color);
  }
}

.input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e9ecef;
  border-radius: 4px;
  padding: 8px 12px;
}

.otp-input {
  input {   
    width: 2.9rem !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  input:focus-visible {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
}

.verify-otp-modal {
  .modal-content{
    width: 650px !important;
  }
}
.personalinfo{
  .initials-circle {
    border-radius: 50%;
  }  
}
.initials-circle {
  align-items: center;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  display: flex;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.mobile-list{
  .initials-circle{
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.name-circle-img{
  .initials-circle{
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}
.mobile-nav {
  .initials-circle{
    width: 100%;
    height: auto;
    font-size: unset;
  }
}

.booking{
  .initials-circle{
    background-color: #e8e8e8;
    margin-right: 10px;
  }
}