@import "scss/_custom.scss";
// @import "../src/assets/webfonts/css/satoshi.css";
// @import "../src/assets/webfonts/css/opensans.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "scss/_button.scss";
@import "scss/typography";
@import "scss/navbar";
@import "scss/footer";
@import "scss/_responsive.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.map-search-card {
  position: relative;

  .searc-icon {
    position: absolute;
    left: 16px;
    top: 45%;
    transform: translateY(-50%);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.userdata {
  .user-imgmain {
    width: 130px;
    height: 130px;
    overflow: hidden;
    display: block;
    margin-right: 10px;
    border-radius: 50%;
  }

  h4 {
    color: #2C2E3A;
    font-size: 16px;
    font-weight: 700;
  }

  hr {
    border-top: 1px solid #16ff96;
    opacity: 1;
  }

  &.card {
    border-radius: 15px !important;
    border: 1px solid #F0F0F0 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

.autoscroll {
  max-height: 450px;
  overflow: auto;
}

.testimonial-data {
  hr {
    border-top: 1px solid #16ff96;
    opacity: 1;
    margin: 40px auto;
  }

  .smalluser {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin-right: 15px;
  }

  h4 {
    color: #2C2E3A;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: #2C2E3A;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
}

.userdesc {
  .testimonial-data {
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    box-shadow: 5px 4px 4px 0px #E0E0E0;
    padding: 30px;
    height: 100%;

    .smalluser {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      margin-right: 15px;
    }

    h4 {
      color: #2C2E3A;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
    }

    p {
      color: #2C2E3A;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  hr {
    border-top: 1px solid #16ff96;
    opacity: 1;
  }

  .slick-track {
    display: flex;
  }



  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    padding: 10px 40px 10px 0;
  }

  .next {
    position: absolute;
    top: -45px;
    right: 45px;
  }

  .prev {
    position: absolute;
    top: -45px;
    right: 0;
  }
}

.listing-data {
  border: 1px solid #F0F0F0;
}

.imglist {
  height: 200px;
  overflow: hidden;
}

.datacard {
  h3 {
    color: #2C2E39;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  h6 {
    color: #1F1F1F;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
  }
}

.btn-outline-black {
  border: 1px solid #F0F0F0;
  color: #000;

  &:hover {
    background: #000 !important;
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }
  }
}


button.kep-login-facebook.metro {
  display: flex !important;
  width: 100% !important;
  gap : 5px;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px 0;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  align-items: center;
  justify-content: center;

  svg {
    fill: #fff !important;
  }

}