/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com >
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

/* LOADER */

.maindiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background-color: #0000005e;
    top: 0;
    left: 0;
    z-index: 999999;
}

.maindiv .logoname {
    width: 190px;
    /* margin-top: 5px; */
}

.maindiv .loadericon {
    position: relative;
    height: 100px;
    width: 100px;
    margin: auto;
}

.maindiv .loadericon .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.maindiv .loadericon .icon img {
    width: 50px;
    filter: brightness(0) invert(1);
}

.maindiv .loadericon .outerCircle {
    background-color: transparent;
    border: 3px solid #16ff96;
    opacity: .9;
    border-left: 3px solid #16ff9640;
    border-radius: 50%;
    top: 0;
    width: 93px;
    height: 93px;
    margin: 0 auto;
    position: relative;
    -moz-animation: spinoffPulse 3s infinite linear;
    -webkit-animation: spinoffPulse 3s infinite linear;
}

@-moz-keyframes spinoffPulse {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinoffPulse {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinoffPulse {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}


/* END LOADER */