.btn {

    font-style: normal;
    font-weight: var(--font-bold);
    font-size: var(--font14);
    line-height: 16px;
    padding: 10px 21px;
    width: auto;
    border-radius: 5px;
    margin-bottom: 15px;
    opacity: 1 !important;

    &.btn-danger {
        background: var(--red);
        color: var(--light-bg);
        border: 0;

        &:hover {

            background: var(--dark-bg);
            color: var(--light-bg);
        }
    }

    &.btn-theme {
        background: var(--primary-color);
        color: var(--dark-bg);
        border: 0;

        &:hover {
            background: var(--dark-bg);
            color: var(--light-bg);
        }
    }

    &.btn-theme-outline {
        background: transparent;
        border: 1px solid var(--primary-color);

    }


    &.btn-shop {
        border: 0;
        color: var(--secondary-color);

        &:hover {
            color: var(--primary-color);

            svg {
                path {
                    stroke: var(--primary-color);
                }
            }
        }
    }

    &.btn-default {

        color: var(--dark-bg);
        background: var(--light-bg);
        border: 1px solid var(--border-color1);

        &:hover {
            svg {
                path {
                    fill: var(--light-bg) !important;
                }
            }

            background: var(--dark-bg);
            border: 1px solid var(--light-bg);
            color: var(--light-bg);
        }

        &.minwidth275 {
            min-width: 275px;
            text-align: left;
            height: 38px;

            &:hover {
                svg {
                    path {
                        fill: var(--light-bg) !important;
                    }
                }

                background: var(--dark-bg);
                border: 1px solid var(--light-bg);
                color: var(--light-bg);
            }
        }

        &:hover {
            background: var(--dark-bg);
            border: 1px solid var(--light-bg);
            color: var(--light-bg);
        }

    }

    &.btn-theme-outline {
        background: var(--light-bg);
        border: 2px solid var(--primary-color);
        color: var(--secondary-color);
        padding: 5px 24px;

        &:hover {
            background: var(--primary-color);
            border: 2px solid var(--primary-color);
            color: var(--secondary-color);
        }
    }

    &.btn-default-custom {
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        height: 43px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        display: flex !important;
        align-items: center;
        gap: 10px;
    }

    &.btn-outline {
        height: 38px;
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        border-radius: 5px;
    }

    &.btn-green {
        background: var(--primary-color);
        color: var(--bs-black);
        padding: 8px 15px !important;
        margin: 0;
    }
}

.loginbtn {
    width: 170px;
    height: 42px;
}

span {
    &.plus_ {
        font-size: 28px;
        color: var(--primary-color);
    }
}