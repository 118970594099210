.backgroundgray {
  background: var(--theme-bg);
}

.bg-white {
  background: #fff;
}

.colorgreen {
  color: var(--primary-color);
}

.colordark {
  color: var(--dark-bg);
}

h2 {
  font-style: normal;
  font-weight: var(--font-bold);
  font-size: var(--theme-h2);
  line-height: 54px;
  color: var(--secondary-color);
}

h3 {
  font-weight: --font-bold;
  font-size: --theme-h3;
  line-height: 49px;
  color: var(--secondary-color);
}

h4,
.font24 {
  font-style: normal;
  font-weight: var(--font-bold);
  font-size: var(--theme-h4);
  line-height: 32px;
  color: var(--secondary-color);
}

h5 {
  font-weight: var(--font-bold);
  font-size: var(--font12);
  line-height: 16px;
  color: var(--secondary-color);
}

p {
  font-style: normal;
  font-weight: var(--font-regular);
  font-size: var(--font16);
  line-height: 22px;
  color: var(--secondary-color);
}

.head16 {
  font-style: normal;
  font-weight: var(--font-bold);
  font-size: var(--font16);
  line-height: 22px;
  color: var(--secondary-color);
}

.head20 {
  font-style: normal;
  font-weight: var(--font-bold);
  font-size: 20px;
  line-height: 22px;
  color: var(--secondary-color);
}

.head12 {
  font-style: normal;
  font-weight: var(--font-bold);
  font-size: var(--font12);
  line-height: 26px;
  color: var(--secondary-color);
}

.font8 {
  font-size: 8px !important;
  line-height: 10px !important;
  color: #fff !important;
}

.font12 {
  font-size: var(--font12);
  line-height: 16px;
}

.font14 {
  font-size: var(--font14);
  line-height: 16px;
}

.font16 {
  font-size: var(--font16);
  line-height: 22px;
}

.font18 {
  font-size: var(--font18);
  line-height: 22px;
}

.font48 {
  font-size: var(--theme-h1);
  line-height: 65px;
}

.font40 {
  font-size: var(--theme-h2);
  line-height: 54px;
}

.font32 {
  font-size: var(--font32);
  line-height: 44px;
}

.font300 {
  font-weight: var(--font-normal);
}
.font400 {
  font-weight: var(--font-regular);
}

.font500 {
  font-weight: var(--font-medium);
}

.font700 {
  font-weight: var(--font-bold);
}

.font900 {
  font-weight: var(--font-black);
}

.secondaycolor {
  color: var(--secondary-color);
}

.mt36 {
  margin-top: 36px;
}

.mb-36 {
  margin-bottom: 36px;
}

.pt-38 {
  padding-top: 38px;
}

.pb-156 {
  padding-bottom: 156px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mt-131 {
  margin-top: 131px;
}

.mb-151 {
  margin-bottom: 151px;
}

.mt-53 {
  margin-top: 53px;
}

.mb28 {
  margin-bottom: 28px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb96 {
  margin-bottom: 96px;
}

.pt-129 {
  padding-top: 129px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-93 {
  padding-bottom: 93px;
}

.pt32 {
  padding-top: 32px;
}

.pb55 {
  padding-bottom: 55px;
}

.pt14 {
  padding-top: 14px;
}

.pb31 {
  padding-bottom: 31px;
}

.ptb-38 {
  padding-top: 38px;
  padding-bottom: 38px;
}

.pt48 {
  padding-top: 48px;
}

.pb132 {
  padding-bottom: 132px;
}

.h80vh {
  height: 80vh;
}

.h70vh {
  height: 70vh;
}

.h90vh {
  height: 90vh;
}

.h65vh {
  height: 65vh;
}

.h60vh {
  height: 60vh;
}

.colorblue {
  color: #0074df;
}

.h40vh {
  height: 40vh;
}

.h75vh {
  height: 75vh;
}

.pt47 {
  padding-top: 47px;
}

.pb49 {
  padding-bottom: 49px;
}

.pb71 {
  padding-bottom: 71px;
}

.pb45 {
  padding-bottom: 45px;
}

.pb29 {
  padding-bottom: 29px;
}
.border-bottom {
  border-bottom: 1px solid #f0f0f0;
}

.font10
{
  font-size: 10px;
}
