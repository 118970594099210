.logout-navbar,
.mobile-nav {
    background-color: var(--secondary-color);
    height: 72px;

    .search-form {
        position: relative;

        input {
            &.Search {
                &.form-control {
                    background: rgba(255, 255, 255, 0.1);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    padding: 8px 65px 8px 12px;
                    gap: 8px;
                    border: 0;
                    color: var(--light-bg);

                    &::placeholder {
                        color: var(--light-bg);
                    }
                }
            }
        }

        span {
            &.searc-icon {
                position: absolute;
                right: 16px;
                top: 5px;
            }

            &.header-cross {
                position: absolute;
                top: 5px;
                right: 42px;
            }
        }
    }

    .navbar-nav {
        a {
            font-size: var(--font14);
            line-height: 19px;
            font-weight: var(--font-medium);
            color: var(--light-bg);
            text-transform: capitalize;
            margin-right: 20px;

            &.active,
            &:hover {
                color: rgb(22, 255, 150);
                border-bottom: 1px solid #16ff96;

                svg {
                    path {
                        stroke: var(--primary-color);

                    }
                }

                &.user-menu {
                    line-height: 20px;

                    &:hover {
                        border-bottom: 0 !important;
                    }
                }
            }


        }

        .show {
            &>.nav-link {
                color: rgb(22, 255, 150);
                border-bottom: 0px solid #16ff96;
            }

            .dropdown-toggle {
                &::after {

                    background: url('../assets/images/downgreen.svg');
                }
            }
        }

        .dropdown-menu {
            &.show {
                margin: 0;
                border-radius: 5px;
                border: 0;
                padding: 15px 0;
                top: 54px;
                min-width: 170px;
                box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
                right: -10px;
                left: auto;

                a {
                    color: var(--menu-a);
                    padding: 6px 15px;
                    margin-bottom: 0px;
                    background: transparent;
                    border: 0;
                    font-weight: var(--font-regular);
                    &:hover {
                        background: rgb(22 255 150 / 10%);
                    }
                }
            }

        }
    }

    .dropdown {
        &.user-menu {
            a {
                margin-right: 0;

                &:hover {
                    border-bottom: 0 !important;
                }
            }
        }
    }

    .dropdown-toggle {
        &::after {
            display: inline-block;
            margin-left: .255em;
            vertical-align: .255em;
            content: "";
            border-top: 0;
            border-right: 0;
            border-bottom: 0;
            border-left: 0;
            background: url('../assets/images/downlight.svg');
            width: 10px;
            height: 5px;
            top: 2px;
            position: relative;
        }

        &:hover,
        &.active {
            &::after {
                background: url('../assets/images/downgreen.svg');
            }
        }
    }

    .navbar-brand {
        svg {
            max-width: 129px;
            height: auto;
        }
    }
}

.loader-img {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(255, 255, 255, 0.85);

    img {
        width: 200px;
        height: 200px;
    }
}

.body-loader {
    overflow: hidden;
}
.top-breadcrumb {
&.catagorybar {
    .css-1hvivo7-control,
    .css-15lsz6c-indicatorContainer .css-1hvivo7-control:hover,
    .css-16rljcq-control {
        border: 0 !important;
        padding: 0 !important;
        width: fit-content;
        font-size: 24px;
        color: #2c2e3a !important;
        font-weight: 700;
    }

    .react-select-3-placeholder,
    .css-1jqq78o-placeholder,
    .css-qbdosj-Input,
    .css-1xc3v61-indicatorContainer {
        color: #2c2e3a !important;
    }
}
}

.catagorybar .css-1u9des2-indicatorSeparator {
    display: none;
}

.catagorybar #react-select-3-input {
    min-width: 0px !important;
    width: 0 !important;
}